<template>
  <span
    class="d-flex"
    @mouseenter.stop="mouseEnter"
    @mouseleave.stop="mouseLeave">
    <slot v-if="right && !left"/>
    <span class="cy-url-copy-btn__tooltip-wrapper d-inline-flex">
      <a
        :class="['anchor-link', 'd-flex', 'align-center', { 'anchor-link--visible': isHovering }]"
        @click.stop="copy">
        <v-icon x-small>
          fa fa-link
        </v-icon>
      </a>
      <CyTooltip
        v-if="isHovering"
        icon="check"
        :activator="tooltipActivator"
        :disabled="!showTooltip"
        :close-delay="closeDelay"
        :value="showTooltip"
        content-class="cy-url-copy-btn__tooltip-content"
        bottom>
        {{ $t('copied') }}
      </CyTooltip>
    </span>
    <slot v-if="left"/>
  </span>
</template>

<script>
export default {
  name: 'CyUrlCopyBtn',
  props: {
    copyValue: {
      type: String,
      default: null,
    },
    closeDelay: {
      type: Number,
      default: 2000,
    },
    left: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    tooltipActivator: null,
    isHovering: false,
    showTooltip: false,
    timeoutHandler: null,
  }),
  methods: {
    copy () {
      const { closeDelay, copyValue } = this
      if (this.timeoutHandler) clearTimeout(this.timeoutHandler)
      this.showTooltip = true
      this.$copyText(copyValue || location.href)
      this.timeoutHandler = setTimeout(() => { this.showTooltip = false }, closeDelay)
    },
    mouseEnter (event) {
      this.tooltipActivator = event.target
      this.isHovering = true
    },
    mouseLeave (ev) {
      this.tooltipActivator = null
      this.isHovering = false
    },
  },
  i18n: {
    messages: {
      en: {
        copied: 'URL copied!',
      },
      es: {
        copied: 'URL copiado!',
      },
      fr: {
        copied: 'URL copié!',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.anchor-link {
  &--visible {
    .v-icon {
      opacity: 1 !important;
    }
  }

  .v-icon {
    width: 14px;
    margin: 0 6px;
    transition: none;
    opacity: 0;

    &:focus,
    &:hover {
      opacity: 1;
      color: get-color("accent");
    }
  }
}
</style>
